import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";
// import Announcements from "../views/Announcements.vue";
import AnnouncementPageV2 from "../views/AnnouncementPageV2.vue";
import Reminders from "../views/Reminders.vue";
import EventsPage from "../views/EventsPage.vue";
import MobileConfig from "../views/MobileAppConfigurationPage.vue";
import EventPageNew from "../views/EventPageNew.vue";
import FormPage from "../components/FormPage.vue";
import EditFormPage from "../components/EditFormPage.vue";
import AddEventPage from "../views/AddEventPage.vue";
// import MessagePage from "../views/MessagePage.vue";
// import MessageChat from "../views/MessageChat.vue";
import ErrorPage from "../views/ErrorPage.vue";
// import CarePlan from "../views/CarePlan.vue"
// import CarePlanExternal from "../views/CarePlanExternal.vue"
import ChatPage from "../views/ChatPage.vue";

import AdminHomepage from '../views/AdminHomepage.vue'
import AdminDashBoard from '../views/admin-homepage/AdminDashBoard.vue'
import UpcomingAssessment from '../views/admin-homepage/UpcomingAssessment.vue'
import ListClient from '../views/admin-homepage/ListClient.vue'
import MasterSchedule from '../views/admin-homepage/MasterSchedule.vue'
import WeeklySchedule from '../views/admin-homepage/WeeklySchedule.vue'
import ListAppointment from '../views/admin-homepage/ListAppointment.vue'
import ReportPage from "../views/admin-homepage/ReportPage.vue"
// import LoginPage from "../views/LoginPage.vue";
import SurveyPage from "../views/admin-homepage/SurveyPage.vue"
// import store from "../store";
// import LoginPage from "../views/LoginPage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path:"/old",
    name:"EventsPageOld",
    component:EventsPage
  },
  // {
  //   path: "/",
  //   name: "LoginPage",
  //   component: LoginPage,
  // },
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },

  {
    path: "/announcements",
    name: "Announcements",
    component: AnnouncementPageV2,
  },
  {
    path: "/reminders",
    name: "Reminders",
    component: Reminders,
  },
  {
    path: "/form/:formtype",
    name: "FormPage",
    component: FormPage,
  },
  {
    path: "/formEdit/:formType/:id",
    name: "EditFormPage",
    component: EditFormPage,
  },
  {
    path: "/chat/:typeUser?/:id?",
    name: "Chat",
    component: ChatPage,
},
  // {
  //   path: "/chat/:typeUser/:id",
  //   name: "Chat",
  //   component: ChatPage,
  // },
  {
    path: "/events",
    name: "EventsPage",
    component: EventPageNew,
  },
  {
    path: "/addevent",
    name: "AddEventPage",
    component: AddEventPage,
  },
  {
    path:"/appconfiguration",
    name:"MobileConfig",
    component:MobileConfig
  },
  // {
  //   path: "/careplan",
  //   name: "CarePlan",
  //   component: CarePlan,
  // },
  // {
  //   path: "/careplanexternal",
  //   name: "CarePlanExternal",
  //   component: CarePlanExternal,
  // },
  {
    path: "*",
    name: "ErrorPage",
    component: ErrorPage,
  },
  {
    path: '/adminhomepage',
    name: 'AdminHomepage',
    component: AdminHomepage,
    meta: {
      adminDashboard: true
    },
    children: [
      {
        path: '/weekly',
        component: WeeklySchedule,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/report',
        name: 'ReportPage',
        component: ReportPage,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/appointment',
        name: 'ListAppointment',
        component: ListAppointment,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/master',
        component: MasterSchedule,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/admindashboard',
        name: 'AdminDashBoard',
        component: AdminDashBoard,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/upcoming-assessment',
        name: 'UpcomingAssessment',
        component: UpcomingAssessment,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/client',
        name: 'ListClient',
        component: ListClient,
        meta: {
          adminDashboard: true,
        },
      },
      {
        path: '/survey',
        name: 'SurveyPage',
        component: SurveyPage,
        meta: {
          adminDashboard: true,
        },
      },
    ],
    
  },
  
  
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to,from, next) => {
//   const loggedInUser = localStorage.getItem('loggedUsername');

//   if(!loggedInUser){
//     if(to.name !== "HomePage"){
//       next({path:'/'});
//     }else{
//       next();
//     }
//   }else{
//     next();
//   }
// })

export default router;
