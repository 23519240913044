<template lang="pug">
b-row(v-if="!isFetchingAdminDashboardData")
    
    b-col.col-2(class="d-none d-xl-block" style="background-color: #50276B;")
        b-row.pl-4(style="color: white; margin-top: 3rem; font-weight: 550;") 
            button.col-4.ml-2.d-flex.justify-content-center.align-items-center(class="home-button" style="font-weight: 500; padding: 0px") 
                router-link(to="/admindashboard" style="color: white; font-weight: bold; text-decoration: none") Dashboard
        b-row(style="color: white; margin-top: 3rem; font-weight: 550")
            b-col.col-12
                b-button(style="height: fit-content; border: none; background-color: inherit; font-weight: 500; cursor: default;") Management
        
        b-row.pl-4.justify-content-start.align-items-start    
            //- div.col-10.mt-4.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem")   
            //-     button.d-flex.justify-content-start.align-items-center(class="appointment-button")
            //-         Icon.mr-2(icon="carbon:report" height="10%" color="white")
            //-         router-link(to="/report" style="color: white; background-color: inherit; text-decoration: none") Report
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem")   
                button.d-flex.justify-content-start.align-items-center(class="appointment-button")
                    Icon.mr-2(icon="guidance:calendar" height="10%" color="white")
                    router-link(to="/appointment" style="color: white; background-color: inherit; text-decoration: none") Appointment
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 1.25rem;") 
                button.d-flex.justify-content-start.align-items-center(class="assessment-button")
                    Icon.mr-2(icon="fe:list-task" height="10%" color="white")
                    router-link(to="/upcoming-assessment" style="color: white; background-color: inherit; text-decoration: none") Assessment
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 1.25rem") 
                button.d-flex.justify-content-start.align-items-center(class="client-button")
                    Icon.mr-2(icon="fluent-mdl2:people" height="10%" color="white")
                    router-link(to="/client" style="color: white; background-color: inherit; text-decoration: none") Clients
            //- div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 1.25rem") 
            //-     button.d-flex.justify-content-start.align-items-center(class="client-button")
            //-         Icon.mr-2(icon="ri:survey-line" height="10%" color="white")
            //-         router-link(to="/survey" style="color: white; background-color: inherit; text-decoration: none") Survey
        
        b-row(style="color: white; margin-top: 3rem; font-weight: 550")
            b-col.col-12
                b-button(style="height: fit-content; border: none; background-color: inherit; font-weight: 500; cursor: default;") Scheduler
        
        
        b-row.pl-4.justify-content-start.align-items-start    
            div.col-10.mt-4.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem") 
                button.d-flex.justify-content-start.align-items-center(class="weekly-button")
                    Icon.mr-2(icon="bi:calendar2-week" height="10%" color="white")
                    router-link(to="/weekly" style="color: white; background-color: inherit; text-decoration: none") Weekly
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem")
                button.d-flex.justify-content-start.align-items-center(class="master-button")
                    Icon.mr-2(icon="ph:calendar-check" height="10%" color="white")
                    router-link(to="/master" style="color: white; font-weight: 500; background-color: inherit; text-decoration: none") Master
    b-col.col-12.col-xl-10
        //- b-row(style="height: 10%; font-size: 3rem")
        //-     b-col.col-12 
                //- b-button.align-items-start.justify-content-start.d-flex(style="font-size: 2.25rem; border: none; background-color: inherit; color: black" @click="toggleComponent") Dashboard 
        router-view()
b-row(v-else)
    b-col.col-12.d-flex.justify-content-center.align-items-center(style="height: 80vh; gap: 10px;" class="flex-column")
        b-spinner(variant="primary" label="Spinning")
        p Please wait while we load the data...
</template>

<script>
import {Icon} from '@iconify/vue2';
import { mapState } from 'vuex';
import { ADMIN_DASHBOARD_NAMESPACE } from '../../store/Modules/AdminDashboard/types-admin-dashboard';

export default{
    components: {
        Icon,
    },
    data(){
        return{
            isHovered: false
        }
    },
    methods: {
        toggleComponent(){
            console.log("toggleComponent clicked");
        },
    },
    computed: {
        ...mapState({
            isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
        }),
    },
}

</script>

<style>
.appointment-button,
.assessment-button,
.client-button, 
.weekly-button,
.master-button,
.home-button{
    background-color: inherit;
    border: none;
    text-decoration: none;
    border-radius: 20px;
    padding: 0px 0px 0px 20px;
}

.appointment-button:hover,
.assessment-button:hover,
.client-button:hover,
.weekly-button:hover,
.master-button:hover,
.home-button:hover{
    background-color: blueviolet;
}

</style>