import * as msal from "@azure/msal-browser";
import store from './index';
import axios from 'axios';
import { computed } from "vue";

const authModule = {
  async init() {
    try {
      const response = await axios.get(`/api/init`, {
        withCredentials: true,
      });
      const logindata = response.data;
      // console.log('logindata', logindata);
      store.commit("logindata", logindata);
  

      const msalConfig = {
        auth: {
          clientId: logindata.MS_APP_ID,
          authority: `https://login.microsoftonline.com/${logindata.TENANT_ID}`,
        },
      };
  
      const msalApp = new msal.PublicClientApplication(msalConfig);
      const accounts = msalApp.getAllAccounts();
      // console.log('accounts', accounts); 
  

      try {
        const health = await axios.get('/health', {
          withCredentials: true,
        });
        console.log('health', health);
  
        if (accounts.length > 0) {
          msalApp.setActiveAccount(accounts[0]);
          store.commit("msalApp", msalApp);
          store.commit("isAdmin", true);
          await this.prepareAxios(msalApp);
        } else {
          await this.handleRedirect(msalApp);
        }
      } catch (healthError) {
        
        if (healthError.response?.status === 401) {
          console.log('Unauthorized - redirecting to login');
          await this.handleRedirect(msalApp); 
        } else {
          throw healthError; 
        }
      }
    } catch (error) {
      if (error.response?.status === 401) {
        console.log('Unauthorized');
      }
      console.error(error);
    }
  }
,  
async handleRedirect(msalApp) {
  // console.log('handleRedirect')
  try {
      const authRes = await msalApp.handleRedirectPromise();
      localStorage.setItem('auth',JSON.stringify(authRes))
      // console.log('authRes',authRes)
      const requestCookies = await axios.post(`/api/set_cookie`,{
        id_token: authRes?.idToken
      },{
        withCredentials: true,
      }
      )
      console.log('requestCookies',requestCookies)
      if(requestCookies.status === 200){
        console.log('cookies set')
      }
      // localStorage.setItem('auth',JSON.stringify(authRes))
      // const expireTimeIn15Minutes = new Date(new Date().getTime() + 15 * 60 * 1000);
      // localStorage.setItem('expireTime',expireTimeIn15Minutes)
      // // eslint-disable-next-line no-unused-vars
      const account = msalApp.getActiveAccount();
      // console.log('account',account)
      if (account) {
          store.commit("msalApp", msalApp);
          store.commit("isAdmin", true);
          await this.prepareAxios(msalApp);
      } else {
          this.login(msalApp);
      }
  } catch (error) {
      console.error(error);
      this.login(msalApp);
  }
},

async login(msalApp) {
  console.log('Logging in...')
  const logindata = store.state.logindata;
  await msalApp.loginRedirect({
    scopes: ['openid', 'profile', `https://${logindata.DATAVERSE_DOMAIN}/.default`],
      redirectUri: location.origin + "/",
  });
},

async prepareAxios() {
  // console.log('prepare axios')
  //eslint-disable-next-line no-unused-vars
  const accessToken = await this.getAccessToken();
  
  
  
  
  const instance = axios.create({
      baseURL: `${location.origin}/api`,
      headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      },
      withCredentials: true,
  });
  // console.log()
  store.commit('api', instance);
},

async getAccessToken() {
  // console.log('getAccessToken')
  const msalApp = store.state.msalApp;
  const logindata = store.state.logindata;
  const accounts = msalApp.getAllAccounts();
  // console.log('accounts',accounts)
  if (accounts.length > 0) {
      msalApp.setActiveAccount(accounts[0]);
      const result = await msalApp.acquireTokenSilent({
          scopes: [`https://${logindata.DATAVERSE_DOMAIN}/.default`],
      });
      return result.accessToken;
  } else {
      throw new Error('No accounts found.');
  }
},

async removeAccount(){
  try {
    const msalApp = store.state.msalApp;
    const account = msalApp.getActiveAccount();
    
    if (account) {
      msalApp.setActiveAccount(null);
      sessionStorage.clear();
      localStorage.removeItem('auth');
      store.commit("msalApp", null);
      store.commit("logindata", null);

      await msalApp.logoutRedirect({
        postLogoutRedirectUri: location.origin,
      })

    } else {
        throw new Error('No accounts found.');
    } 
  } catch (error) {
    console.error('Unable to logout', error)
  }
},

displayname : computed(() =>{
  const msalApp = store.state.msalApp;
  if (msalApp) {
    const account = msalApp.getActiveAccount();
    return account ? account.name : null;
  }
  return null;
}),

username : computed(() =>{
  const msalApp = store.state.msalApp;
  if (msalApp) {
    const account = msalApp.getActiveAccount();
    return account ? account.username : null;
  }
  return null;
}),
};

export default authModule;