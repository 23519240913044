import Vue from 'vue'
import Vuex from 'vuex'
import { Message } from './Modules/Message'
import { Table } from './Modules/Table'
import events from './Modules/Events/Events'
import axios from 'axios'
import adminDashboard from './Modules/AdminDashboard/AdminDashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      msalApp: null,
      logindata: null,
      isAdmin: false,
      axios: null,
      api:null,
      currentAdmin: null,
      accounts: null,
      isLoadingTwilio: false,
      loadedAdminDashboard: false,
  },
  mutations: {
    modifyApi(state) {
        state.api.defaults.headers.Authorization = `${JSON.stringify(state.msalApp.getActiveAccount().idTokenClaims)}`
    },
    accounts(state, payload) {
      state.accounts = payload;
    },
    isAdmin(state, payload) {
      state.isAdmin = payload;
    },
    msalApp(state, payload) {
       state.msalApp = payload;
    },
    currentAdmin(state, payload) {
      state.currentAdmin = payload;
    },
    logindata(state, payload) {
        state.logindata = payload;
    },
    axios(state, payload) {
        state.axios = payload;
    },
    api(state, payload){
      state.api = payload;
    },
    setIsLoadingTwilio(state, isLoading){
      state.isLoadingTwilio = isLoading;
    },
    setLoadedAdminDashboard(state, loaded){
      state.loadedAdminDashboard = loaded
    }
  },
  actions: {
    async getEveryGroup(){
      try {
        // console.log('getEveryGroup index')
        const { data } = await axios.get('/api/every_group');
        return data;
        
      } catch (error) {
        console.error(error);
        return null;
      }

    },
  },
  modules: {
    Message,
    Table,
    events,
    adminDashboard,
  }
})
